import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { Grid, useTheme, useMediaQuery } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import {
  fetchEventsForTrip,
  saveEvent,
  copyEvent,
  toggleFavoriteEvent,
} from "../utils/event_utils";
import { Loader, MapView, ViewBar } from "./";
import { CopyTripDialog, CropPhotoDialog } from "./Modals";
import {
  getFile,
  uploadFile,
  deleteFile,
  convertToBase64,
} from "../utils/storage_utils";
import { ShareTripSummary } from ".";
import { fetchCollaboratorsForTrip } from "../utils/collaborator_utils";
import { addDays, utcDate, getDay } from "../utils/date_utils";
import { filterEventsByDate } from "../utils/event_utils";
import {
  incrementTripViewCounter,
  saveTrip,
  copyTrip,
} from "../utils/trip_utils";
import { validatePlaces } from "../utils/maps_utils";
import { eventBackgroundColorFallback } from "../constants";
import "bootstrap-icons/font/bootstrap-icons.css";
import {
  handleEventMouseEnter,
  handleEventMouseLeave,
} from "../utils/calendar_utils";

export const ShareTrip = () => {
  const { trip_id } = useParams();
  let navigate = useNavigate();
  const theme = useTheme();
  const smallScreenSize = theme.breakpoints.values.sm;
  const screenIsSmall = useMediaQuery(`(max-width:${smallScreenSize}px)`);

  const [calendarApi, setCalendarApi] = useState(null);
  const calendarRef = useCallback((element) => {
    setCalendarApi(element?.getApi());
  }, []);
  const [calendarContainerHeight, setCalendarContainerHeight] = useState(0);
  const calendarContainerRef = useCallback((element) => {
    setCalendarContainerHeight(element?.clientHeight);
  }, []);

  const [trip, setTrip] = useState(undefined);
  const [events, setEvents] = useState(undefined);
  const [tripOwner, setTripOwner] = useState(undefined);
  const [tripFile, setTripFile] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [uploadAlert, setUploadAlert] = useState(false);
  const [collaborators, setCollaborators] = useState([false]);
  const [currentUserIsEditor, setCurrentUserIsEditor] = useState(false);
  const [copyTripDialogOpen, setCopyTripDialogOpen] = useState(false);
  const [cropPhotoDialogOpen, setCropPhotoDialogOpen] = useState(false);

  const [calendarView, setCalendarView] = useState("listMonth");
  const [currentDay, setCurrentDay] = useState(0);
  const [firstDay, setFirstDay] = useState(0);
  const [calendarTitle, setCalendarTitle] = useState(undefined);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [imgSrc, setImgSrc] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [showFavorites, setShowFavorites] = useState(false);
  const [tripView, setTripView] = useState(
    localStorage.getItem(`sharetrip_${trip_id}_view`) || "calendar"
  );
  const [validPlaces, setValidPlaces] = useState(null);
  const [filteredStartDate, setFilteredStartDate] = useState(undefined);
  const [filteredEndDate, setFilteredEndDate] = useState(undefined);

  const hasSession =
    localStorage.getItem("first_name") &&
    localStorage.getItem("last_name") &&
    localStorage.getItem("user_id") &&
    localStorage.getItem("email")
      ? true
      : false;

  useEffect(() => {
    if (trip_id) {
      incrementTripViewCounter({
        id: trip_id,
      }).then((trip) => {
        if (trip && !trip.is_public) {
          // TO DO implement confirm here, wasn't working so went with alert for now instead
          // also should think about if this page should always be available to owner + editors regardless of is_public
          // this would add complexity to overall sharing flow + require further changes to open graph utils
          alert("Trip is not public!");
          navigate("/home");
        }
        setTrip(trip);
        fetchEventsForTrip({ tripId: trip_id, callee: "share" }).then(
          (events) => {
            setEvents(events);
            setValidPlaces(validatePlaces(events.all));
          }
        );
        fetchCollaboratorsForTrip({ tripId: trip_id }).then((collaborators) => {
          if (collaborators && !collaborators.error) {
            setCollaborators(collaborators);
            setTripOwner(
              collaborators && collaborators.find((c) => c.tripOwner)
            );
          }
        });
      });

      getFile({ id: trip_id, bucket: "trips" }).then((file) => {
        if (file.message === "File not found") {
          setTripFile(file.message);
        } else if (!file.error) {
          setTripFile({ ...file, base64: convertToBase64(file) });
        }
      });
    }
  }, [trip_id, calendarView, navigate]);

  useEffect(() => {
    if (trip) {
      setStartDate(utcDate(trip.start_date));
      setEndDate(utcDate(trip.end_date));
    }
  }, [trip]);

  useEffect(() => {
    if (collaborators) {
      const currentUserIsOwnerOrCollaborator = collaborators.find((oC) => {
        if (
          oC &&
          localStorage.getItem("user_id") &&
          oC.userId === localStorage.getItem("user_id")
        ) {
          return true;
        }
        return false;
      });
      setCurrentUserIsEditor(currentUserIsOwnerOrCollaborator ? true : false);
    }
  }, [collaborators]);

  useEffect(() => {
    if (calendarView) setFirstDay(getDay(startDate));
  }, [calendarView, startDate]);

  const UploadHandler = ({ file }) => {
    const uploadFailureTimeout = setTimeout(() => {
      setUploadAlert({
        severity: "error",
        message: "Something went wrong. Try again.",
      });
      setLoading(false);
    }, 30000);
    cropPhotoDialogOpen && setCropPhotoDialogOpen(false);
    uploadFile({
      file,
      id: trip_id,
      bucket: "trips",
    }).then((response) => {
      if (response.status === 500) {
        setUploadAlert({
          severity: "error",
          message: response.message,
        });
      } else if (response.status === 200) {
        setTripFile({ ...response, base64: convertToBase64(response) });
      }
      setLoading(false);
      clearTimeout(uploadFailureTimeout);
    });
  };

  const DeleteHandler = () => {
    deleteFile({ id: trip_id, bucket: "trips" }).then((response) => {
      if (response) {
        setTripFile("File not found"); //short circuit
        setLoading(false);
      }
    });
  };

  const CopyTripHandler = ({ updatedTripInfo, updatedEvents }) => {
    setLoading(true);
    const newTrip = copyTrip({ originalTrip: trip, updatedTripInfo });
    const copyTripFailureTimeout = setTimeout(() => {
      setLoading(false);
    }, 30000);
    // unsure of spread operator here
    saveTrip({ ...newTrip }).then(async (result) => {
      if (result) {
        updatedEvents.forEach((event) => {
          copyEvent({ event, originalTrip: trip, newTrip: result });
        });

        let eventData = await Promise.all(
          updatedEvents.map(async (event) => {
            const savedEvent = saveEvent({ ...event });
            return savedEvent;
          })
        );

        if (eventData) {
          setLoading(false);
          navigate(`/trip/${result.id}`);
        }

        clearTimeout(copyTripFailureTimeout);
      }
    });
  };

  const SelectFileHandler = ({ file }) => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result?.toString() || "");
        setCropPhotoDialogOpen(true);
        setLoading(false);
        setSelectedFile({ name: file.name, type: file.type });
      });
      reader.readAsDataURL(file);
    }
  };

  const handleEventClick = ({ event, el }) => {
    if (currentUserIsEditor) {
      const matchingEvent = events.all.find((item) => {
        return item.id === event._def.publicId;
      });

      toggleFavoriteEvent({
        id: matchingEvent.id,
        isFavorite: !matchingEvent.favorite,
      }).then((result) => {
        if (result) {
          fetchEventsForTrip({ tripId: trip_id, callee: "share" }).then(
            (events) => {
              setEvents(events);
              if (showFavorites && events.favorites.length === 0)
                setShowFavorites(false);
            }
          );
        }
      });
    }
  };

  useEffect(() => {
    filterEventsByDate({
      startDate: filteredStartDate,
      endDate: filteredEndDate,
      events: events,
      callback: setEvents,
    });
  }, [filteredEndDate, filteredStartDate, tripView]);

  return trip && events && tripOwner ? (
    <>
      <Grid item xs={12}>
        <ShareTripSummary
          tripFile={tripFile}
          selectFileHandler={SelectFileHandler}
          deleteHandler={DeleteHandler}
          tripOwner={tripOwner}
          loading={loading}
          setLoading={setLoading}
          setAlert={setUploadAlert}
          alert={uploadAlert}
          hasSession={hasSession}
          trip={trip}
          currentUserIsEditor={currentUserIsEditor}
          setCopyTripDialogOpen={setCopyTripDialogOpen}
          collaborators={collaborators.filter((c) => c.userId)}
        />
      </Grid>
      <Grid item xs={12} mt={1}>
        <ViewBar
          calendarTitle={calendarTitle}
          setCalendarTitle={setCalendarTitle}
          calendarView={calendarView}
          setCalendarView={setCalendarView}
          currentDay={currentDay}
          setCurrentDay={setCurrentDay}
          startDate={startDate}
          endDate={endDate}
          calendarApi={calendarApi}
          showAddEventButton={false}
          showShareButton={false}
          showFavorites={showFavorites}
          setShowFavorites={setShowFavorites}
          events={events}
          currentUserIsEditor={currentUserIsEditor}
          validPlaces={validPlaces}
          tripView={tripView}
          setTripView={setTripView}
          callee="sharetrip"
          filteredStartDate={filteredStartDate}
          setFilteredStartDate={setFilteredStartDate}
          filteredEndDate={filteredEndDate}
          setFilteredEndDate={setFilteredEndDate}
        />

        <Grid
          item
          xs={12}
          sx={{
            visibility: tripView === "calendar" ? "visible" : "hidden",
            height: tripView === "calendar" ? "auto" : 0,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              minHeight: screenIsSmall ? "400px" : "600px",
            }}
            ref={calendarContainerRef}
          >
            <FullCalendar
              timeZone="utc"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              headerToolbar={null}
              // https://github.com/fullcalendar/fullcalendar/issues/3340
              initialView={calendarView}
              firstDay={firstDay ? firstDay : 0}
              initialDate={startDate}
              validRange={{
                start: startDate.$d,
                end:
                  process.env.NODE_ENV === "development"
                    ? endDate.$d
                    : addDays(endDate.$d, 1),
              }}
              events={showFavorites ? events.favorites : events.all}
              ref={calendarRef}
              eventContent={(eventInfo) => {
                // https://codepen.io/arshaw/pen/ExPXjpY?editable=true&editors=001
                return {
                  html: eventInfo.event._def.extendedProps.customHtml,
                };
              }}
              displayEventTime={calendarView === "dayGridMonth" ? true : false}
              scrollTime="08:00:00"
              height={calendarContainerHeight}
              eventColor={eventBackgroundColorFallback}
              eventClick={handleEventClick}
              eventMouseEnter={(info) => {
                if (!screenIsSmall) {
                  handleEventMouseEnter(info);
                }
              }}
              eventMouseLeave={(info) => {
                if (!screenIsSmall) {
                  handleEventMouseLeave(info);
                }
              }}
            />
          </Grid>
        </Grid>

        {tripView === "map" ? (
          <Grid
            container
            sx={{
              minHeight: screenIsSmall ? "400px" : "600px",
            }}
          >
            <MapView
              places={events}
              callee="sharetrip"
              showFavorites={showFavorites}
              styleOverrides={{
                height: screenIsSmall ? "400px" : "600px",
              }}
            />
          </Grid>
        ) : (
          ""
        )}
      </Grid>

      {copyTripDialogOpen && trip ? (
        <CopyTripDialog
          trip={trip}
          open={copyTripDialogOpen}
          handleClose={() => setCopyTripDialogOpen(false)}
          copyTripHandler={CopyTripHandler}
          events={events}
          loading={loading}
        />
      ) : (
        ""
      )}

      {cropPhotoDialogOpen && imgSrc ? (
        <CropPhotoDialog
          imgSrc={imgSrc}
          open={cropPhotoDialogOpen}
          handleClose={() => setCropPhotoDialogOpen(false)}
          selectedFile={selectedFile}
          uploadHandler={UploadHandler}
          loading={loading}
          setLoading={setLoading}
          circularCrop={false}
          aspectRatio={3 / 1}
        />
      ) : (
        ""
      )}
    </>
  ) : (
    <Loader />
  );
};
