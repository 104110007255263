import React, { useEffect, useState } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { EmptyState, ExploreSearch, ExploreResults, Loader } from "./";
import { NoResultsRedirectButton } from "./Buttons";
import { fetchTripsByLocation } from "../utils/trip_utils";
import { EmptyResultsExplanation, NoResultsExplanation } from "../constants";

// TODO browser back button share trip to explore is not correct
// i.e. navigate from share trip to explore via clicking on trip's location
// then go back, there are extra explore renders
// fairly minor so leaving as is for now but wanted to record
export const Explore = () => {
  const hasSession =
    localStorage.getItem("first_name") &&
    localStorage.getItem("last_name") &&
    localStorage.getItem("user_id") &&
    localStorage.getItem("email")
      ? true
      : false;
  const exploreEnabled = localStorage.getItem("enable_explore") === "true";

  const n = { string: "Six", value: 6 };

  let location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [searchLocation, setSearchLocation] = useState(undefined);
  const [lat, setLat] = useState(undefined);
  const [lng, setLng] = useState(undefined);
  const [trips, setTrips] = useState(undefined);

  // listen for location
  useEffect(() => {
    if (!exploreEnabled) navigate("/home");

    const { pathname, search } = location;
    if (pathname && search) {
      setLoading(true);
      setTrips([]);

      const searchParams = new URLSearchParams(search);
      const queryParam = searchParams.get("query");
      const latParam = searchParams.get("lat");
      const lngParam = searchParams.get("lng");

      setSearchLocation(queryParam);
      setLat(latParam);
      setLng(lngParam);

      fetchTripsByLocation({
        location: queryParam,
        lat: latParam,
        lng: lngParam,
      }).then((result) => {
        if (result) {
          setTrips(result);
          setLoading(false);
        }
      });
    } else if (typeof trips === "undefined") {
      setLoading(true);
      fetchTripsByLocation({
        location: null,
        lat: null,
        lng: null,
        n: n.value,
      }).then((result) => {
        if (result) {
          setTrips(result);
          setLoading(false);
        }
      });
    }
  }, [location, exploreEnabled]);

  // listen for searchLocation, i.e. autocomplete and apply values to searchParams
  // triggering location useEffect above
  useEffect(() => {
    if (searchLocation && lat && lng) {
      setLoading(true); //set here to avoid race condition
      setSearchParams({ query: searchLocation, lat: lat, lng: lng });
    } else {
      searchParams.delete("query");
      searchParams.delete("lat");
      searchParams.delete("lng");
      setSearchParams(searchParams);
      setTrips([]);
    }
  }, [searchLocation, lat, lng]);

  return (
    <Grid container>
      <Grid item xs={12} mt={2}>
        <ExploreSearch
          loading={loading}
          location={searchLocation}
          setLocation={setSearchLocation}
          lat={lat}
          setLat={setLat}
          lng={lng}
          setLng={setLng}
        />
      </Grid>

      {trips?.length > 0 ? (
        <Grid item xs={12} mt={2}>
          <ExploreResults
            trips={trips}
            setTrips={setTrips}
            label={
              searchLocation?.length === 0
                ? `${n.string} most viewed trips`
                : "Results"
            }
          />
        </Grid>
      ) : (
        ""
      )}

      {loading ? <Loader styleOverrides={{ height: "50vh" }} /> : ""}

      {trips?.length === 0 && !loading ? (
        <EmptyState
          styleOverrides={{ height: "50vh" }}
          children={
            searchLocation.length > 0 ? (
              <>
                <Typography
                  component="div"
                  sx={{
                    color: "rgba(0, 0, 0, 0.6)",
                    fontWeight: "500",
                    fontSize: "0.875rem",
                  }}
                >
                  {NoResultsExplanation}
                </Typography>
                <NoResultsRedirectButton
                  searchLocation={searchLocation}
                  hasSession={hasSession}
                />
              </>
            ) : (
              <Typography
                component="div"
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontWeight: "500",
                  fontSize: "0.875rem",
                }}
              >
                {EmptyResultsExplanation}
              </Typography>
            )
          }
        />
      ) : (
        ""
      )}
    </Grid>
  );
};
